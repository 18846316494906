.hack4bengal__section7 {
  padding-top: 2rem;
  color: white;
  font-family: "Poppins";
  margin: 0;
}

.hack4bengal__section7-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hack4bengal__section7-part1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hack4bengal__section7-title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -1px;
  color: #ffffff;
  text-align: center;
  margin: 2rem 0;
}

.hack4bengal__section7-content {
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  width: 85%;
}

.hack4bengal__section7-part2 {
  max-width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  min-height: 800px;
  gap: 6rem;
}

.section7-heading {
  font-size: 23px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.section7-accordion {
  list-style: none;
}

.section7-accordion_item {
  border-bottom: 0.5px solid #ffffff;
  /*  width: 90%; */
}

.section7-button {
  background-color: #1B1B1B;
  color: #fff;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px;
  cursor: pointer;
  border: none;
}

.section7-control {
  font-size: 2rem;
}

.section7-answer {
  background-color: #1B1B1B;
  padding: 18px 0px;
  padding-top: 4px;
  font-size: 20px;
  line-height: normal;
  text-align: left;
  font-weight: 300;
}

.section7-question {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  font-family: "Poppins";
  letter-spacing: -0.5px;
}

/* activate toggle */
.section7-accordion_item.active .button {
  background-color: #15131c;
}

.section7-answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

.hack4bengal__section7-image {
  width: 40%;
}

.hack4bengal__section7-image img {
  width: 100%;
}

.hack4bengal__section7-questions {
  max-width: 70%;
}

@media screen and (max-width: 1000px) {
  .section7-question {
    max-width: 90%;
  }


  .hack4bengal__section7-part2 {
    max-width: 100%;
  }

  .hack4bengal__section7-questions {
    max-width: 90%;
  }

  .hack4bengal__section7-image {
    display: none;
  }

  .hack4bengal__section7-part2 {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;
  }

  .hack4bengal__section7-questions ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .hack4bengal__section7-image {
    width: 30%;
  }
}

@media screen and (max-width: 650px) {
  .hack4bengal__section7-title {
    font-size: 36px;
  }

  .hack4bengal__section7-content {
    font-size: 16px;
  }

  .section7-button {
    padding: 0;
  }

  .section7-question {
    font-size: 14px;
    padding: 12px 0;
  }

  .section7-answer {
    font-size: 14px;
  }

  .section7-control {
    font-size: 1.2rem;
  }
}