@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

.hack4bengal__section4-speaker-card {
    background: #FFF9F9;
    box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    padding-bottom: 0.5rem;
    max-width: 370px;
    max-height: 500px;
    min-height: 500px;
}

.hack4bengal__section4-speaker-img-container {
    width: 240px;
    height: 240px;
    display: flex;
}

.hack4bengal__section4-speaker-img {
    /*  max-width: 100%; */
    width: 240px;
    height: 240px;
    margin: auto;
    height: auto;
    border-radius: 50%;
}

.hack4bengal__section4-speaker-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hack4bengal__section4-speaker-menu h1 {
    font-weight: 700;
    font-size: 27px;
    line-height: 33px;
    /*  text-transform: uppercase; */
    text-align: center;
    color: black;
    padding-top: 1rem;
    margin-bottom: 10px;
}

.hack4bengal__section4-speaker-menu h3 {
    font-size: 20px;
    line-height: 20px;
    color: #ff1717;
    padding-top: 0.5rem;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 10px;
}

.hack4bengal__section4-speaker-menu p {
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding: 1rem 0;
}

.hack4bengal__section4-speaker-divider {
    border: 1px solid #D7D7D7;
    width: 100%;
}

.hack4bengal__section4-speaker-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0;
}

.hack4bengal__section4-speaker-contact h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;
}

.hack4bengal__section4-speaker-contact-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    padding-top: 0.5rem;
}

.hack4bengal__section4-speaker-contact-links-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hack4bengal__section4-speaker-contact-links img {
    cursor: pointer;
    width: 28px;
}

@media screen and (max-width: 650px) {
    /*   .hack4bengal__section4-speaker-card {
        transform: scale(0.9);
    } */
}

@media screen and (max-width: 480px) {
    .hack4bengal__section3-events-container .slider .slick-list .slick-slide>div {
        padding: 0 0;
    }

    .hack4bengal__section3-events-container .slider .slick-list {
        margin: 0 0;
    }

}

@media (min-width: 1024px) {
    .hack4bengal__section3-events-container .slider .slick-list .slick-slide.slick-active.slick-current+.slick-slide {
        transition: all 1000ms ease-out;
        scale: 1.1;
        transform: translateY(-50px);
    }
}