.hack4bengal__section3 {
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: white;
  font-family: "Poppins";
}

.hack4bengal__section3-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hack4bengal__section3-title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  letter-spacing: 1px;
}

.hack4bengal__section3-events-container {
  max-width: 98%;
}

.hack4bengal__section3-events-container .slider .slick-list {
  margin: 0 2rem;
  box-sizing: initial;
  padding: 80px 0px;
  overflow-y: visible;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.hack4bengal__section3-events-container .slider .slick-arrow .slick-prev button {
  display: none;
}

.hack4bengal__section3-events-container .slider .slick-list .slick-slide>div {
  padding: 0 35px;
}

@media (min-width: 1024px) {
  .hack4bengal__section3-events-container .slider .slick-list .slick-slide.slick-active.slick-current+.slick-slide {
    transition: all 1000ms ease-out;
    scale: 1.1;
    transform: translateY(-30px);
  }
}


@media screen and (max-width: 1000px) {
  .hack4bengal__section3-body {
    margin: 0 1rem;
  }

  .hack4bengal__section3 {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 650px) {
  .hack4bengal__section3-events-container .slider .slick-list .slick-slide>div {
    padding: 0 0;
  }

  .hack4bengal__section3-slider {
    padding-top: 1rem;
  }

  .hack4bengal__section3-title {
    font-size: 36px;
  }
}