.container {
  width: 100%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
body {
  background: var(--color-bg);
}

.background__bg {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.section__padding {
  padding: 2rem 8rem;
}
.section__margin {
  margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 2rem;
  }
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 2rem 2rem;
  }
  .section__margin {
    margin: 2rem 2rem;
  }
}

.slide-bottom {
  animation: slide-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-1%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@media screen and (min-width: 350px) {
  .container {
    max-width: 350px;
  }
}

@media screen and (min-width: 450px) {
  .container {
    max-width: 450px;
  }
}

@media screen and (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media screen and (min-width: 1300px) {
  .container {
    max-width: 1300px;
  }
}

@media screen and (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
