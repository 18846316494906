.hack4bengal__section9 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: white;
    font-family: 'Poppins';
    margin-top: 5rem;
}

.hack4bengal__section9-boxtitle,
.hack4bengal__section9-box,
.hack4bengal__section9-title {
    color: white;
    font-family: 'Poppins', sans-serif;
}

.hack4bengal__section9-description {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}

.hack4bengal__section9-title1 {
    font-style: medium;
    font-weight: 470;
    font-size: 48px;
    line-height: 82px;
    text-align: center;
    letter-spacing: 1px;

}

.hack4bengal__section9-title2 {
    font-style: medium;
    font-weight: 370;
    font-size: 40px;
    line-height: 82px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 1rem;

}

.hack4bengal__section9-box {
    width: 40%;
    font-size: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /*   background-color: #222222; */
    justify-content: center;
    padding: 4rem;
    border-radius: 40px;
    margin: 2rem 0;

    background: rgba(34, 34, 34, 0.25);
    box-shadow: 0 8px 32px 0 rgba(237, 238, 244, 0.37);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    cursor: pointer;
}

.hack4bengal__section9-box:hover {

    box-shadow: 0 8px 32px 0 rgba(135, 33, 31, 0.57);
}



.hack4bengal__section9-boxtitle {
    font-size: 30px;
    font-style: normal;
    margin: 1rem 0;
    padding-bottom: 3%;
    color: #E13B3C;
    font-weight: 600;
    text-align: center;
}

.hack4bengal__section9-title hr {
    width: 10%;
    margin: auto;
    border: 1px solid #E13B3C;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
    .hack4bengal__section9-box {
        width: 80%;
    }
}

@media screen and (max-width: 650px) {
    .hack4bengal__section9-title1 {
        font-size: 36px;
        line-height: normal;
    }

    .hack4bengal__section9-title2 {
        line-height: normal;
        font-size: 16px;
    }

    .hack4bengal__section9-box {
        width: 100%;
        padding: 3.5rem;
    }

    .hack4bengal__section9-boxtitle {
        font-size: 24px;
    }

    .hack4bengal__section9-boxInfo {
        font-size: 16px;
    }
}

/* //* Prizes section */

.prizes_box_main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 8rem;
    padding: 1rem;
    gap: 2rem;
}

.prizebox {
    min-height: 16rem;
    position: relative;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    min-width: 20rem;

}

.prizebox img {
    width: 8rem;
}

.prizebox1 {
    margin-top: 5rem;
    min-height: 16rem;
}

.prizebox2 {
    margin-top: 0rem;
    min-height: 10rem;
    display: block;
}

.prizebox3 {
    margin-top: 5rem;
    min-height: 16rem;
}

.prizebox4 {
    display: none;
}

.prizebox1 {
    margin-top: 5rem;
    min-height: 16rem;
}

.prizebox1_imgdiv {
    position: absolute;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: -4.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.prizes_textdiv {
    width: 100%;
    margin: auto;
    border-radius: 15px;
    transition: all .8s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    min-height: 95%;
    background: linear-gradient(180deg, #142137, #0b121f 99.99%, rgba(20, 33, 55, 0));
    color: #fff;
}

.prizes_textdiv:hover {
    box-shadow: 0 0 10px 0 #e13b3c;
}

.prizes_textdiv2 {
    min-height: 80%;
}



.prize_textdiv_texts {
    font-family: Poppins;
    display: flex;
    height: 100%;
    flex-direction: column;
    font-weight: 700;
    justify-content: space-between;
}

.prize_textdiv_texts h1 {
    margin-top: 1rem;
    font-size: 4rem;
}

.prize_textdiv_texts h2 {
    font-size: 1.75rem;
}

.prizes_btndiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

@media screen and (max-width: 1300px) {
    .prizes_box_main {
        flex-wrap: wrap;
        margin-top: 0rem;
    }

    .prizebox1 {
        margin-top: 0rem;
        min-height: 16rem;
    }

    .prizebox2 {
        /*  */
        display: none;
    }

    .prizebox4 {
        display: block;
        margin-top: 0rem;
        min-height: 16rem;
    }

    .prizes_textdiv2 {
        min-height: 95%;
    }

    .prizebox3 {
        margin-top: 0rem;
        min-height: 16rem;
    }

    .prizes_textdiv {
        margin-top: 90px;
        padding-top: 40px;
    }

    .prizebox1_imgdiv {
        margin-top: 3rem;
    }

    .prizes_btndiv {
        margin-top: 5rem;

    }
}