.hack4bengal__section1 {
  /* display: flex; */
  background: url("../../assets/img/hero1.png") no-repeat;
  background-size: cover;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  min-height: 100vh;
}

.hack4bengal__section1-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
}

.hack4bengal__section1-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.hack4bengal__section1-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 56px;
  color: #ffffff;
  margin: 1rem;
  margin-bottom: 2rem;
}

.hack4bengal__section1-subheading {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 36px;
  line-height: 82px;
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
}

.hack4bengal__section1-para {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 26px;
  letter-spacing: 1px;
  color: white;
  text-align: center;
  width: 60%;
}

.hack4bengal_section1_header {
  width: 100%;
  z-index: 1;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.909);
  border-radius: 12px;
  max-width: fit-content;
  cursor: pointer;
}

.hack4bengal_section1_header img {
  width: 120px;
}

.hack4bengal_section1_header h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: 1px;
  color: #ff1717;
  text-align: center;
  padding: 1.5rem;
}

.hack4bengal__section1-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  width: 50%;
}

/* .banner {
  width: inherit;
} */

.hack4bengal__section1-button {
  align-items: center;
  background-color: #f3f6fb;
  border-radius: 4px;
  border: 2px solid transparent;
  color: #000;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  height: 44px;
  justify-content: center;
  outline: none;
  padding: 0 32px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}

.hack4bengal__section1-devfolio {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.hack4bengal__section1-discord {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.909);
  padding: 9px 14px 8px;
  border-radius: 4px;
  margin-left: 14px;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  color: #ff1717;
}

.hack4bengal__section1-discord-link img {
  height: 17px;
  width: auto;
}
#discord {
  padding-left: 23px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 23px;
}

.hack4bengal__section1-discord-link span {
  margin-top: -15rem;
}

.hack4bengal__section1-register {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.909);
  padding: 12px 22px 13px;
  border-radius: 4px;
  margin-left: 14px;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  color: #ff1717;
}
/* 
.hack4bengal__section1-register span {
  color: #ff1717;
} */

.hack4bengal__section1-side-links {
  position: absolute;
  left: 48px;
  bottom: 54px;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 2.5rem;
  text-align: center;
}

.hack4bengal__section1-side-links::before {
  display: block;
  content: "";
  width: 2px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.4);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.hack4bengal__section1-side-links a svg:hover {
  fill: #ff5757;
  transition: all 0.2s ease-in-out;
}

.hack4bengal__section1-scrollDown {
  color: white;
  position: absolute;
  bottom: 4rem;
  right: 2.5rem;
  transform-origin: right top 0;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: bounce 4s infinite;
  animation-delay: 2s;
}

.hack4bengal__section1-scrollDown a {
  display: inline-block;
  font-family: var(--font-family);
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  color: #ffffff;
  writing-mode: vertical-lr;
  background: transparent;
  position: relative;
  margin-bottom: 0.6rem;
}

.hack4bengal__section1-scrollDown a:hover {
  color: #ff1717;
  transition: all 0.2s ease-in-out;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@media screen and (max-width: 1000px) {
  .hack4bengal__section1-scrollDown,
  .hack4bengal__section1-side-links {
    display: none;
  }

  .hack4bengal_section1_header img {
    display: none;
  }

  .hack4bengal__section1-para {
    width: 80%;
  }

  .hack4bengal_section1_header h1 {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 1px;
    color: #ff1717;
    text-align: center;
    padding: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .hack4bengal__section1-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
  }
  .hack4bengal__section1 {
    background-position: center;
  }
}

@media screen and (max-width: 700px) {
  .hack4bengal__section1-heading {
    font-size: 40px;
  }

  .hack4bengal__section1-para {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .hack4bengal__section1-para {
    width: 100%;
  }

  .hack4bengal_section1_header {
    margin-bottom: 2rem;
  }
  .hack4bengal_section1_header h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 480px) {
  .hack4bengal_section1_header h1 {
    font-size: 25px;
  }
  .hack4bengal__section1-heading {
    font-size: 28px;
  }
}
