.hack4bengal__team-card {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  min-width: 320px;
  max-width: 320px;
}

.hack4bengal__team-card .hack4bengal__team-card-img {
  display: inline-block;
  height: 170px;
  width: 170px;
  margin-bottom: 15px;
  z-index: 1;
  position: relative;
}

.hack4bengal__team-card .hack4bengal__team-card-img::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #ff1717;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.hack4bengal__team-card:hover .hack4bengal__team-card-img::before {
  height: 100%;
}

.hack4bengal__team-card .hack4bengal__team-card-img::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff1717;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hack4bengal__team-card .hack4bengal__team-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.hack4bengal__team-card:hover .hack4bengal__team-card-img img {
  box-shadow: 0 0 0 14px #fff;
  transform: scale(0.75);
}

.hack4bengal__team-card .name {
  display: block;
  font-size: 22px;
  color: #ff1717;
  text-transform: capitalize;
}

.hack4bengal__team-card .title {
  display: block;
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 500;
  color: #e47b73;
  text-transform: capitalize;
  margin-top: 4px;
}

.domain3 {
  color: #000;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.hack4bengal__team-card .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #ff1717;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.hack4bengal__team-card:hover .social {
  bottom: 0;
}

.hack4bengal__team-card .social li {
  display: inline-block;
}

.hack4bengal__team-card .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.hack4bengal__team-card .social li a:hover {
  color: #1369ce;
  background-color: #f7f5ec;
}
