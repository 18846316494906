.hack4bengal__section8-problems-container1 {
  display: grid;
  grid-template-columns: repeat(2 ,1fr);
  gap: 4rem;
  width: 80%;
}

.hack4bengal__section8-problems-container2 {
  display: grid;
  grid-template-columns: repeat(2 ,1fr);
  gap: 4rem;
  width: 80%;
  transition: display 3s ease-in-out;
  margin-top: 4rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }  
  100% {
    opacity: 0;
  }
}


.hack4bengal__section8-cardtitle {
  font-weight: 600;
  margin: 0.5rem 0;
  min-height: 3rem;
  display: flex;
  align-items: center;
  line-height: 24px;
}

.hack4bengal__section8-line {
  border: 0.1px solid rgb(230, 230, 230);
  margin: 0.5rem 0;
  font-weight: lighter;
}

.hack4bengal__section8-carddetails {
  margin: 1rem 0;
  color: rgb(220, 220, 220);
}

.hack4bengal__section8-image {
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-radius: 16px;
}

.hack4bengal__section8-image:hover {
  transform: scale(0.98);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.hack4bengal__section8-problemModal-body {
  position: fixed;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: white;
  color: black;
  border-radius: 20px;
}

.problemModal-close {
  width: 1.5rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  color: white;
}


@media screen and (max-width: 650px) {
  .hack4bengal__section8-problems-container1 {
    grid-template-columns: repeat(1 ,1fr);
  }

  .hack4bengal__section8-problems-container2 {
    grid-template-columns: repeat(1 ,1fr);
  }
}

