/* .timer{
  display: grid;
  justify-content: space-around;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    justify-items: center;
  font-family: "Poppins";



}


.timer-core{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-width: 8px;
  border-style: solid;
  border-color: #E13B3C;
  padding: 1rem;
  font-size: 2rem;
  font-weight: bold;
  width: 15rem;
  height: 15rem;
  box-shadow: 0 0 10px #E13B3C;

  animation: glowing 2s ease-in-out infinite;

  text-shadow: 0 0 5px #fff;

}

@media screen and (max-width: 991px) {
  .timer{
    flex-direction: column;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);

  }
  .timer-core{
    margin-top: 1rem;
    font-size: 1.5rem;
    height: 10rem;
    width: 10rem;
  }
}
 */


.timer-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  justify-items: center;
  width: 60%;
  font-size: 3rem;
  color: #fff;
  font-weight: bolder;

}

.time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  animation: glowingt 2s ease-in-out infinite;

}

.time-label {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
}

/* glowingt animation */

@keyframes glowingt {
  0% {
    /* color: #E13B3C; */
    text-shadow: 0 0 7px #E13B3C;
  }

  50% {
    /* color: #E13B3C; */
    text-shadow: 0 0 14px #E13B3C;
  }

  100% {
    /* color: #E13B3C; */
    text-shadow: 0 0 7px #E13B3C;
  }
}

@media screen and (max-width: 991px) {
  .timer-body {
    font-size: 3rem;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    justify-items: center;
    justify-content: center;
  }

  .separator {
    display: none;
  }

  .timer-mar {
    margin-top: 25px;
  }
}