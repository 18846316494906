.hack4bengal__section4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: "Poppins";
}

.hack4bengal__section4-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hack4bengal__section4-title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 82px;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 4rem;
  color: white;
}

.hack4bengal__section4-speakers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 5rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
  .hack4bengal__section4-speakers {
    gap: 2rem;
    flex-direction: column;
  }

  .hack4bengal__section4-title {
    font-size: 36px;
  }
}


hr {
  width: 10%;
  margin: auto;
  border: 1px solid #E13B3C;
  margin-bottom: 2rem;
}