@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.h4b_about_main {
    margin-top: 5rem;
    color: white;
    font-family: "Poppins"

}

.h4b_about_main h1 {
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 8rem;
}

.h4b_about_main hr {
    width: 10%;
    margin: auto;
    border: 1px solid #E13B3C;
    margin-bottom: 2rem;
}

.h4b_about_sub {
    display: flex;
    /*     justify-content: space-between; */
    gap: 3rem;
    align-items: center;
    justify-content: center;
}

.playeryoga {
    width: 400px;
    /* height: 300px; */
}

.h4b_about_textdiv {
    font-size: 20px;
    width: 50%;

}

.h4b_about_textdiv span {
    color: #E13B3C;

}

.h4b_about_more {
    display: flex;
    gap: 1rem;
    padding-top: 2rem;
}

.h4b_about_more_element img {
    height: 3rem;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 10px;
}

.h4b_about_more_element_title {
    color: #E13B3C !important;
    font-weight: 600;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .h4b_about_sub {
        flex-direction: column;
        align-items: center;
        padding: 0 2rem;
        justify-content: center;
        text-align: center;
    }

    .h4b_about_textdiv_sub {
        text-align: center !important;
    }


    .h4b_about_textdiv {

        width: 100%;

    }


}

@media screen and (max-width: 769px) {
    .h4b_about_more {
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width: 430px) {
    .playeryoga {
        height: auto;
        width: 90vw;
        margin: auto;
    }
}