.hack4bengal__section3-events-container .slider .slick-list {
    margin: 0 2rem;
    box-sizing: initial;
    padding: 80px 0px;
    overflow-y: visible;
  }
  
  .slick-prev:before,
  .slick-next:before {
    display:none;
  }
  
  .hack4bengal__section3-events-container .slider .slick-arrow .slick-prev button{
    display: none;
  }
  .hack4bengal__section3-events-container .slider .slick-list .slick-slide > div {
    padding: 0 35px;
  }
  
  @media (min-width: 1024px) {
    .hack4bengal__section3-events-container
      .slider
      .slick-list
      .slick-slide.slick-active.slick-current
      + .slick-slide {
      transition: all 1000ms ease-out;
      scale: 1.1;
      transform: translateY(50px);
    }
  }

.hack4bengal__section3-card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: white;
}

.hack4bengal__section3-image {
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.hack4bengal__section3-image:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.hack4bengal__section3-cardTitle {
  font-weight: 600;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.hack4bengal__section3-divider {
  border: 0.1px solid rgb(230, 230, 230);
  margin-top: 0.5rem;

  font-weight: lighter;
  width: 100%;
}

.hack4bengal__section3-details {
  margin: 1rem 0;
  color: rgb(220, 220, 220);
}
