.prize_main_div {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 5rem;
}

.sponsored_prizes_titlediv {
    margin-top: 2rem;
}


.prize_box {
    background: linear-gradient(180deg, #142137, #0b121f 99.99%, rgba(20, 33, 55, 0));

    border-radius: 14px;
    padding: 2rem;
    width: 40%;

    cursor: pointer;
}

.prize_box:hover {
    box-shadow: 0 0 10px 0 #e13b3c;
    transition: all .3s ease-in-out;
}


.prize_box img {
    height: 30px;
    max-width: 100%;
}


.prize_box_text h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: .02em;
    color: #fff;
    margin-top: 3rem;
}

.prize_box_text h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /*  text-align: justify; */
    letter-spacing: .02em;
    color: #fff;
    margin-top: 1rem;
    opacity: .8;
}

@media screen and (max-width: 1025px) {
    .prize_box {
        width: 100%;
    }

    .prize_main_div {
        padding: 0 1rem;
    }

}