.hack4bengal__team {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: "Poppins";
}

.hack4bengal__team-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hack4bengal__team-header-container {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}

.hack4bengal__team-header-container img {
  width: 150px;
  height: auto;
}

.hack4bengal__team-main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  letter-spacing: 1px;
  color: white;
}

.hack4bengal__team-subtitle {
  font-style: normal;
  font-size: xx-large;
  font-weight: 500;
  text-align: center;
  color: #e13b3c;
  letter-spacing: 1px;
}

.hack4bengal__team-title {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 4rem;
  margin-bottom: 10px;
  color: white;
}

.hack4bengal__team-members {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
  .hack4bengal__team-header-container img {
    width: 80px;
    height: auto;
  }

  .hack4bengal__team-main-title {
    font-size: 24px;
    margin-left: 1rem;
  }

  .hack4bengal__team-speakers {
    gap: 2rem;
    flex-direction: column;
  }

  .hack4bengal__team-title {
    font-size: 36px;
  }
}

.hack4bengal__team-body hr {
  width: 10%;
  margin: auto;
  border: 1px solid #e13b3c;
  margin-bottom: 4rem;
}
