.hack4bengal__section10 {
  padding-top: 4rem;
  color: white;
  font-family: "Poppins";
}

.hack4bengal__section10-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hack4bengal__section10-title {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 82px;
  text-align: center;
  letter-spacing: 1px;

}

.hack4bengal__section10-title2 {
  margin-bottom: 3rem;
}

.hack4bengal__section10-button {
  border: 1px solid #ff1717;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 12px 40px;
  text-transform: uppercase;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: #d7d7d7;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

.hack4bengal__section10-button:hover {
  transition: all 0.2s ease-in-out;
  color: white;
  background-color: #ff1717;
  border: 1px solid #ff1717;
}

@media screen and (max-width: 650px) {
  .hack4bengal__section10-title {
    font-size: 36px;
    line-height: normal;
  }
}