.hack4bengal__team-card3 {
  position: relative;
  background-color: #fff9f9;
  width: 300px;
  height: 420px;
  border-radius: 30px;
  margin: 3rem 0;
}
.hack4bengal__team-card3-img {
  position: relative;
  top: -35px;
  text-align: center;
  border-radius: 50%;
}
.hack4bengal__team-card3-img img {
  border: 8px solid #e13b3c;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  object-fit: cover;
  transition: transform 0.5s;
}
.hack4bengal__team-card3-img img:hover {
  transform: scale(1.2);
}
.hack4bengal__team-card3-content {
  text-align: center;
  color: #e47b73;
  font-weight: 900;
}
.name3 {
  position: relative;
  top: -10px;
  font-size: 1.4rem;
  color: #ff1717;
  font-weight: 600;
}
.title3 {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 500;
  position: relative;
}
.domain3 {
  color: #000;
  font-size: 1rem;
  margin-bottom: 1rem;
  top: -15px;
}
.line {
  width: 80%;
  margin: 0 2rem;
  border-top: 2px solid #231f20;
}
.social3 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.6rem;
}
.social3 li {
  list-style: none;
}
